import React, { useReducer, createContext, useEffect } from "react";
import {
  CHANGE_LANGUAGE,
  CHANGE_PAGE,
  HOME_PAGE_CLICKED_PRODUCT,
  CLICKED_PRODUCT_NAME,
} from "../types";
import "./index.css";

import "bootstrap/dist/css/bootstrap.min.css";
export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const initialState = {
  language: "en",
  page: "",
  clickedProduct: "",
  nameGlobalClickedProduct: "",
};

function reducer(state, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }
    case CHANGE_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case HOME_PAGE_CLICKED_PRODUCT: {
      return {
        ...state,
        clickedProduct: action.payload,
      };
    }
    case CLICKED_PRODUCT_NAME: {
      return {
        ...state,
        nameGlobalClickedProduct: action.payload,
      };
    }
    default:
      console.log("bad action");
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onLanguageChange = (lang) => {
    dispatch({ type: CHANGE_LANGUAGE, payload: lang });
  };

  useEffect(() => {
    var languageUrl = window.location.href;
    if (languageUrl.includes("/dk/")) {
      onLanguageChange("dk");
    } else if (languageUrl.includes("/es/")) {
      onLanguageChange("es");
    } else if (languageUrl.includes("/de/")) {
      onLanguageChange("de");
    } else if (languageUrl.includes("/fr/")) {
      onLanguageChange("fr");
    }
  }, []);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
